.App {
  text-align: center;
  min-width: 1000px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/public/fonts/Montserrat-SemiBold.ttf') format('ttf');
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the body takes up at least the full viewport height */
  font-family: 'Montserrat', sans-serif;
}

.container {
  flex: 1; /* Make the container flex grow to fill remaining space */
  display: flex;
  flex-direction: column;
}

main {
  flex: 1; /* Allow the main content to grow and fill remaining space */
  padding: 20px; /* Optional: Add padding to the main content */
}

@media (min-width: 601px) and (max-width: 768px) {
  .app_logos_div{
    width: 40vw;
    margin: auto;
    margin-top: 40vh;
    margin-bottom: 6vh;
    color: #EE4296;
  }

  .logo_column{
    width: 20vw;
  }

  .small_screen_message_title{
    font-size: 2.8em;
    color: #282c34;
    font-family: 'Montserrat', sans-serif;
    width: auto;
    margin: auto;
  }

  .small_screen_message_title_div{
    width: auto;
    margin: auto;
  }

  .type_app_store{
    font-size: 1.8em;
    width: 20vw;
    text-align: center;
    margin: auto;
    margin-top: 1vh;
    color:#282c34;
    font-weight: 200;
  }
}

@media (min-width: 200px) and (max-width: 600px) {
  .app_logos_div{
    width: 80vw;
    margin: auto;
    margin-top: 30vh;
    margin-bottom: 10vh;
    color: #EE4296;
  }

  .logo_column{
    width: 40vw;
  }

  .small_screen_message_title{
    font-size: 2em;
    color: #282c34;
    font-family: 'Montserrat', sans-serif;
    width: auto;
    margin: auto;
  }

  .small_screen_message_title_div{
    width: auto;
    margin: auto;
  }

  .type_app_store{
    font-size: 1.5em;
    width: 40vw;
    text-align: center;
    margin: auto;
    margin-top: 1vh;
    color:#282c34;
    font-weight: 200;
  }

}