.desktop_perfil {
  background-color: #F5F5F5;
  width: 100%;
  overflow: hidden;
}

.desktop_perfil .div {
  background-color: #F5F5F5;
  height: 90vh;
  position: relative;
  width: 100vw;
}

.desktop_perfil .overlap-group {
  /* Fondo Gris */
  background-color: #F5F5F5;
  background-size: 100% 100%;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  overflow-x: hidden;
}

.desktop_perfil .rectangulo_principal {
  background: linear-gradient(to right, #F5F5F5 7.31%, white 5%);
  height: auto;
  left: 0.4vw;
  position: absolute;
  top: 3vh;
  width: 59.2vw;
  align-content: left;
  border-bottom: 15vh solid #F5F5F5;
  border-radius: 0.5vw;
}

.desktop_perfil .rectangulo-arriba {
  background-color: white;
  background-size: 100% 100%;
  height: auto;
  left: 64vw;
  position: absolute;
  top: 3vh;
  width: 30vw;
  text-align: center;
  border-radius: 0.5vw;
}

.desktop_perfil .segundo-rectangulo {
  background-color: white;
  background-size: 100% 100%;
  height: auto;
  margin-top: 3vh;
  width: 29.5vw;
  left: 64vw;
  position: absolute;
  top: 60vh;
}

.desktop_perfil .flex-container {
  display: flex;
  flex-direction: row;
}

.desktop_perfil .group {
  height: 15vh;
  position: relative;
  width: 25vw;
  margin-left: 4vw;
  border: 50px solid rgba(80, 177, 201, 0.1);
  border-bottom: transparent;
  background-color: white;
  border-radius: 220px 220px 0 0;
}

.desktop_perfil .rectangle-fucsia {
  height: 22vh;
  width: 30vw;
  background-color: rgba(80, 177, 201, 0.1);
  margin-left: 10vw;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/public/fonts/Montserrat-SemiBold.ttf') format('ttf');
}

.num_candidata {
  font-family: 'Montserrat';
  font-size: 1.4vw;
  font-weight: 700;
  letter-spacing: 0;
  text-align: left;
  margin-left: 7vw;
  margin-top: 6.5vw;
  color: #5A5A5A;
}

.cargo_candidata {
  font-family: 'Montserrat';
  font-size: 1vw;
  font-weight: 600;
  letter-spacing: 0.1vw;
  text-align: left;
  margin-left: 18.1vw;
  margin-top: -2.8vw;
  margin-bottom: 1vw;
  color: #5A5A5A;
}

.brief_candidata {
  font-family: 'Montserrat';
  font-size: 1vw;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  margin-left: 7vw;
  margin-top: -0.5vw;
  color: #5A5A5A;
}

.perfil-image {
  width: 20%;
  height: auto;
  max-width: 100%;
  object-fit: cover;

}

#atras {
  width: 20vw;
  height: 2.5vw;
  font-size: 0.8vw;
  font-family: 'Montserrat';
  border-radius: 8vw;
  border: 0;
  padding-top: 0.5vw;
  padding-bottom: 0.4vw;
  background-color: #50B1C9;
  color: white;
  text-decoration: none;
  margin-top: 32vw;
  position: absolute;
  margin-left: 20vw;
  text-align: center;
}

.desktop_perfil .linea_celeste {
  color: #50B1C9;
  height: 1vh;
  left: 18.5vw;
  position: absolute;
  top: 22vh;
  width: 40.8vw;
  border-top: 0.2vw solid #50B1C9;
}

.desktop_perfil .linea_celeste_corta {
  color: #50B1C9;
  height: 1vh;
  left: 4.3vw;
  position: absolute;
  top: 22vh;
  width: 8.7vw;
  border-top: 0.2vw solid #50B1C9;
}

.desktop_perfil .elipse-celeste {
  height: 5vw;
  width: 5.3vw;
  background-color: transparent;
  border: 0.2vw solid #50B1C9;
  border-radius: 50%;
  margin-left: 5vw;
}

.desktop_perfil .rectangle_gris {
  height: 35vh;
  left: 0.3vw;
  position: absolute;
  top: 0;
  width: 4vw;
  background-color: #F5F5F5;
}

.desktop_perfil .rectangulo_seccion {
  width: 57.3vw;
  text-align: left;
  height: auto;
  margin-bottom: 2vw;
  margin-top: -6.5vw;
}

.perfil-info-container {
  display: flex;
  align-items: flex-start;
  /* Para alinear los elementos al inicio del contenedor */
  position: relative;
}

.perfil-image {
  width: 12vw;
  /* Ajusta el tamaño según sea necesario */
  height: auto;
  object-fit: cover;
  position: absolute;
  /* Posiciona la imagen de forma absoluta */
  margin-left: 10vw;
  margin-top: 2vw;
}

.info-usuaria {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  /* Espacio entre los elementos de la información */
  margin-left: 3vw;
  margin-top: -1vw;
}

.usuaria-title {
  margin: 0;
  font-weight: bold;
}

.usuaria-resp {
  margin: 0;
}

.busqueda-title {
  font-family: 'Montserrat';
  font-size: 1vw;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  margin-left: 1.4vw;
  color: #5A5A5A;
}

.busqueda-resp {
  font-family: 'Montserrat';
  font-size: 1vw;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  margin-top: -0.6vw;
  margin-left: 1.4vw;
  color: #9E9D9D;
}

.usuaria-title {
  font-family: 'Montserrat';
  font-size: 1vw;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  margin-left: 26vw;
  color: #5A5A5A;
}

.usuaria-resp {
  font-family: 'Montserrat';
  font-size: 1vw;
  font-weight: 400;
  letter-spacing: 0;
  text-align: left;
  margin-top: -0.6vw;
  margin-left: 26vw;
  color: #9E9D9D;
}

.porcen {
  font-family: 'Montserrat';
  font-size: 1.6vw;
  text-align: center;
  color: #50B1C9;
  position: absolute;
  transform: translate(-40%, -130%);
  /* Ajuste para centrar completamente */
  font-weight: 500;
  margin-left: 7.6vw;
  margin-top: -1vw;
}

.porcentaje-container {
  position: relative;
  width: 6.3vw;
  height: 6vw;
  margin-top: -3vw;
  margin-left: 8vw;
}

.space-bottom {
  height: 2vw;
  /* Espacio en blanco al final */
}