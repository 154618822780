.rectangulo-busquedas{
    position: absolute;
    width: 30vw;
    height: 52vw;
    background: #50B1C94D;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    z-index: 0;
}

.container-busquedas{
    border: 1px solid gray;
    box-shadow: 1px 1px 3px gray;
    border-radius: 10px;
    flex-direction: column;
    margin-top: 4vw;
    margin-left: 23vw; 
    padding: 1vw;
    width: 55vw;
    height: 30vw;
    z-index: 100;
    position: absolute;
    background-color: white;
}

.rojo{
    color: red;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/public/fonts/Montserrat-SemiBold.ttf') format('ttf');
}

.letras-rojas{
  font-family: 'Montserrat';
  font-size: 0.75vw;
  color:#5A5A5A;
  width: 20vw;
  margin-top: 1vw;
}

.texto1-busquedas{
    font-family: 'Montserrat';
    font-size: 1.2vw; 
    font-weight: 700;
    line-height: 1vh; 
    letter-spacing: 0; 
    text-align: center;
    color: #5A5A5A;
}

.wot-circulo{
    position: absolute;
    width: 55vw;
    height: 52vw;
    z-index: 0;
    margin-left: 5vw; 
    opacity: 0.3;
}

.porcentaje{
    position: absolute;
    margin-top: 26vw;
    margin-left: -0.2vw;
    color: #323232;
    font-size: 0.9vw;
}

progress[value] {
    width: 17vw;
    height: 0.6vw;
    position: absolute;
    margin-top: 26.3vw;
    margin-left: -18vw;
    appearance: none;
    -webkit-appearance: none;
  
  }
progress[value]::-webkit-progress-bar {
    background-color: #F5F5F5;
    border-radius: 10px;

  }
progress[value]::-webkit-progress-value {
    background-color: #50B1C9;
    border-radius: 10px;
  }

.numero1-busquedas{
  position: absolute;
  border-radius: 100%;
  padding-left: 1.8vw;
  padding-right: 1.8vw;
  padding-top: 0.8vw;
  padding-bottom: 0.8vw;
  border: 2px solid white; 
  box-shadow: 1px 1px 1px gray;
  z-index: 150;
  left: 23.5%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  background-color: white;
  margin-top: 10vw;
  font-size: 2vw;
  font-weight: bolder;
  color: gray;
}
  #active{
    border: 2px solid #50B1C9; 
    box-shadow: 1px 1px 1px #50B1C9;
    font-weight: bolder;
  }
  .numero2-busquedas{
    position: absolute;
    border-radius: 100%;
    padding-left: 1.8vw;
    padding-right: 1.8vw;
    padding-top: 0.8vw;
    padding-bottom: 0.8vw;
    border: 2px solid white; 
    box-shadow:  1px 1px 1px gray;;
    z-index: 150;
    left: 23.5%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */ 
    background-color: white;
    margin-top: 19vw;
    font-size: 2vw;
    font-weight: bolder;
    color: gray;
    
  }
  .numero3-busquedas{
    position: absolute;
    border-radius: 100%;
    padding-left: 1.8vw;
    padding-right: 1.8vw;
    padding-top: 0.8vw;
    padding-bottom: 0.8vw;
    border: 2px solid white; 
    box-shadow:  1px 1px 1px gray;;
    z-index: 150;
    left: 23.5%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    background-color: white;
    margin-top: 28vw;
    font-size: 2vw;
    font-weight: bolder;
    color: gray;
    
  }
  
  .busqueda-busquedas{
    position: absolute;
    width: 10vw;
    margin-top: 11vw;
    z-index: 150; 
    left: 16%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    color: #50B1C9;
    font-family: 'Montserrat';
    font-size: 1.1vw; 
    font-weight: 700;
    line-height: 1vh; 
    letter-spacing: 0; 
    text-align: center;
  }

  .perfilProfesional-busquedas{
    position: absolute;
    width: 10vw;
    margin-top: 20vw;
    z-index: 150; 
    left: 16%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    color: #50B1C9;
    font-family: 'Montserrat';
    font-size: 1.1vw; 
    font-weight: 700;
    letter-spacing: 0; 
    text-align: center;
  
  }
  .requisitos-busquedas{
    position: absolute;
    width: 10vw;
    margin-top: 29vw;
    z-index: 150; 
    left: 16%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    color: #50B1C9;
    font-family: 'Montserrat';
    font-size: 1.1vw; 
    font-weight: 700;
    line-height: 1vh; 
    letter-spacing: 0; 
    text-align: center;
  }
  

  /* Formulario   1 */
  .nombreBusqueda {
    position: absolute;
    font-size: 0.85vw;
    font-family: 'Montserrat';
    line-height: 15px; 
    letter-spacing: 0em;
    text-align: left;
    width: 25.625vw; 
    height: 1.85vh; 
    margin-top: 2vw; 
    margin-left: 4vw;
    
}
  .input-nombre{
    font-family: 'Montserrat';
    position: absolute;
    width: 20vw;
    height: 2.2vw;
    margin-top: 0.5vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }

  
  .cargoBuscado{
    font-family: 'Montserrat';
    position: absolute; 
    font-size: 0.85vw;
    margin-top: 7.2vw; 
    margin-left: 4vw;
    text-align: left;
    width: 20vw;
    margin-bottom: 1vh;
  }
  .input-busquedas{
    font-family: 'Montserrat';
    position: absolute;
    width: 20vw;
    height: 2.5vw;
    margin-top: 1.6vw;
    margin-left: -8.6vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .jornadaBuscada{
    font-family: 'Montserrat';
    position: absolute; 
    margin-top: 13.6vw; 
    margin-left: 4vw;
    margin-bottom: 1vh;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-jornada{
    font-family: 'Montserrat';
    position: absolute;
    width: 20vw;
    height: 2.5vw;
    margin-top: 1.6vw;
    margin-left: -14.8vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .modalidadBuscada{
    font-family: 'Montserrat';
    position: absolute; 
    margin-top: 15vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-modalidad{
    font-family: 'Montserrat';
    position: absolute;
    width: 20vw;
    height: 2.5vw;
    margin-top: 1.6vw;
    margin-left: -5.3vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .areaBuscada{
    font-family: 'Montserrat';
    position: absolute; 
    margin-top: 8.3vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-area{
    font-family: 'Montserrat';
    position: absolute;
    width: 20vw;
    height: 2.5vw;
    margin-top: 1.6vw;
    margin-left: -5.5vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .regionBuscada{
    font-family: 'Montserrat';
    position: absolute; 
    margin-top: 2vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-region{
    font-family: 'Montserrat';
    position: absolute;
    width: 20vw;
    height: 2.5vw;
    margin-top: 1.6vw;
    margin-left: -8.5vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .horasRequeridas{
    font-family: 'Montserrat';
    position: absolute; 
    margin-top: 22.5vw; 
    margin-left: 31vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-horas{
    font-family: 'Montserrat';
    position: absolute;
    width: 20vw;
    height: 2.3vw;
    margin-top: 1.4vw;
    margin-left: 0vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .trabajoFlexible{
    font-family: 'Montserrat';
    position: absolute; 
    margin-top: 19vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-trabajo{
    font-family: 'Montserrat';
    position: absolute;
    width: 20vw;
    height: 2.5vw;
    margin-top: 0.7vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  .necesidadViaje{
    font-family: 'Montserrat';
    position: absolute; 
    margin-top: 14.5vw; 
    margin-left: 4vw;
    text-align: left;
    font-size: 0.85vw;
    width: 20vw;
  }
  .input-viaje{
    font-family: 'Montserrat';
    position: absolute;
    width: 20vw;
    height: 2.5vw;
    margin-top: 0.5vw;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 0.85vw;
  }
  #siguiente-busquedas{
    width: 10vw;
    height: 3.5vw;
    background-color: #50B1C9;
    border: solid; 
    border-radius: 10px;
    padding-left: 2vw; /*con flecha 4 */
    padding-right: 2vw; /*con flecha 9 */ 
    padding-top: 1vw;
    padding-bottom: 1vw;
    color: white;
    text-decoration: none;
    margin-top: 24vw;
    position: absolute;
    margin-left: 17.9vw;
    font-size: 1vw;
  }
  #atras-busquedas{
    width: 10vw;
    height: 3.5vw;
    background-color: #5A5A5A;
    opacity: 0.5;
    border: solid; 
    border-radius: 10px;
    padding-left: 2vw; 
    padding-right: 2vw; 
    padding-top: 1vw;
    padding-bottom: 1vw;
    color: white;
    text-decoration: none;
    margin-top: 24vw;
    position: absolute;
    margin-left: 7.6vw;
    font-size: 1vw;
  }

/* BUSQUEDA 2 */

.profesion-busquedas{
  font-family: 'Montserrat';
  position: absolute;
  font-size: 0.85vw;
  line-height: 15px; 
  letter-spacing: 0em;
  text-align: left;
  width: 25.625vw; 
  height: 1.85vh; 
  margin-top: 2vw; 
  margin-left: 4vw;
  }
.input-profesion{
  font-family: 'Montserrat';
  border-radius: 5px;
  width: 20vw;
  border: 1px solid #D9D9D9;
  margin-top: 2vw;   
  font-size: 1vw;
}

.ejemplo-profesion{
  font-family: 'Montserrat';
  font-size: 0.75vw;
  color:#5A5A5A;
  width: 20vw;
  position: absolute;
  margin-top: 0.4vw;
}
.anosBuscados{
  font-family: 'Montserrat';
  position: absolute; 
  margin-top: 2vw; 
  margin-left: 31vw;
  text-align: left;
  font-size: 0.85vw;
}

.input-rangoAnos{
  font-family: 'Montserrat';
  position: absolute;
  width: 20vw;
  height: 2.6vw;
  margin-top: 2.5vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
  margin-left: -21.5vw;
}
.areas-busquedas{
  font-family: 'Montserrat';
  position: absolute; 
  margin-top: 12vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-areas{
  font-family: 'Montserrat';
  border-radius: 5px;
  width: 20vw;
  border: 1px solid #D9D9D9;
  margin-top: 2vw;
  font-size: 1vw;
}
.ejemplo-area{
  font-family: 'Montserrat';
  font-size: 0.75vw;
  color:#5A5A5A;
  width: 20vw;
  position: absolute;
  margin-top: 0.4vw;
}

.industria-busquedas{
  font-family: 'Montserrat';
  position: absolute; 
  margin-top: 2vw; 
  margin-left: 31vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-industria{
  font-family: 'Montserrat';
  border-radius: 5px;
  width: 20vw;
  border: 1px solid #D9D9D9;
  margin-top: 2vw;   
  font-size: 1vw;
}
.ejemplo-industria{
  font-family: 'Montserrat';
  font-size: 0.75vw;
  color:#5A5A5A;
  width: 20vw;
  position: absolute;
  margin-top: 0.4vw;
}
.altaDireccion{
  font-family: 'Montserrat';
  position: absolute; 
  margin-top: 2vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-ad{
  font-family: 'Montserrat';
  position: absolute;
  width: 20vw;
  height: 2.2vw;
  margin-top: 0.5vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
.experienciaDirectorios{
  font-family: 'Montserrat';
  position: absolute; 
  margin-top: 8vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
}
.input-ed{
  font-family: 'Montserrat';
  position: absolute;
  width: 20vw;
  height: 2.5vw;
  margin-top: 0.5vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}

  /* BUSQUEDA4 */
.postgrado-busquedas{
  font-family: 'Montserrat';
  position: absolute; 
  margin-top: 2vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
  
}
.input-postgrado{
  font-family: 'Montserrat';
  position: absolute;
  width: 20vw;
  height: 2.2vw;
  margin-top: 0.5vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
}
.resultadoPerso{
  font-family: 'Montserrat';
  position: absolute; 
  margin-top: 7vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
  
}
.input-personalidad{
  font-family: 'Montserrat';
  position: absolute;
  width: 20vw;
  height: 2.5vw;
  margin-top: 1.5vw;
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  font-size: 0.85vw;
  margin-left: -6vw;
}
.competencias-busquedas{
  font-family: 'Montserrat';
  position: absolute; 
  margin-top: 2vw; 
  margin-left: 31vw;
  text-align: left;
  font-size: 0.85vw;
  
}
.input-competencias{
  font-family: 'Montserrat';
  border-radius: 5px;
  width: 20vw;
  border: 1px solid #D9D9D9;
  margin-top: 2vw;   
  font-size: 1vw;
}
.idioma-busquedas{
  font-family: 'Montserrat';
  position: absolute; 
  margin-top: 14.5vw; 
  margin-left: 4vw;
  text-align: left;
  font-size: 0.85vw;
  width: 20vw;
  
}
.input-idiomas{
  font-family: 'Montserrat';
  border-radius: 5px;
  width: 20vw;
  border: 1px solid #D9D9D9;
  margin-top: 2vw;   
  font-size: 1vw;
}

.declaracion{
  font-family: 'Montserrat';
  position: absolute;
  margin-left: 4vw;
  margin-top: 2vw;
  font-size: 0.85vw;
}
.terminos{
  font-family: 'Montserrat';
  position: absolute;
  margin-left: 4vw;
  margin-top: 5vw;
  font-size: 0.85vw;
}
.terminos2{
  font-family: 'Montserrat';
  position: absolute;
  margin-left: -15vw;
  margin-top: -3vw;
  font-size: 0.85vw;
  color: #50B1C9;
}
.politicas2{
  font-family: 'Montserrat';
  position: absolute;
  margin-left: -1vw;
  margin-top: -3vw;
  font-size: 0.85vw;
  color: #50B1C9
}
.terminos-politicas{
  font-family: 'Montserrat';
  position: absolute;
  margin-left: -5vw;
  margin-top: 12vw;
  font-size: 0.85vw;
  width: 50vw;
}

.WT1 {
  text-align: center;
  font-family: 'Montserrat-Medium';
}

.container{
  border: 1px solid gray;
  box-shadow: 1px 1px 3px gray;
  border-radius: 10px;
  flex-direction: column;
  margin-top: 8vw;
  margin-left: 20vw; 
  padding: 1vw;
  width: 60vw;
  height: 35vw;
  z-index: 100;
  position: absolute;
  background-color: white;
}

.texto1{
  font-family: 'Montserrat';
  font-size: 1.2vw; 
  font-weight: 700;
  line-height: 1vh; 
  letter-spacing: 0; 
  text-align: center;
  color: #5A5A5A;
}

.WT-logo2 {
  height: 20vw;
  width: 30vw;
  background-color: white;
  border-color: white;
  z-index:1;
  border-radius: 10px;
  margin-top: 2vw;
}

.texto5{
  font-family: 'Montserrat';
  position: absolute;
  font-size: 1.2vw; 
  letter-spacing: 0; 
  margin-left: -26vw; 
  margin-top: 1vw; 
  text-align: center;
  color: #5A5A5A;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-size: 1.5em;
  font-family: 'Montserrat';
  color: #5A5A5A;
}

.blurred {
  filter: blur(5px);
  pointer-events: none;
}