/* Header.css */
header {
    max-height: 7vw;
    overflow: hidden; /* Opcional: para ocultar el contenido que se desborda si excede la altura máxima */
    /* Otros estilos de la barra de encabezado aquí */
    background-color: #ffffff;
   
  }
.logo {
    width:7vw;
    float:left;
    margin-left: 4vw;
    margin-top: 0.5vw;
}

.navlink:hover .navlink_icon,
.navlink:hover .navlink-text {
    color: #50B1C9 !important;
}

.header_navigation {
    margin-left: 45vw;
    align-items: flex-start;
    display:flex;
    
}

.navlink {
    font-size: 1.3em;
    margin-right: 2vw;
    border: none;
    background-color: transparent;
    padding: 0;
    
}
.navlink-text {
    display: block;
    text-align: center;
    font-size: 0.8vw; 
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #5A5A5A;
    
}

.icon-container {
    display: inline-block;
    text-align: center;
}

ul {
    list-style: none;
    margin-left: 20vw;
}
  
li {
    display: inline;
    margin-right: 20px; /* Add spacing between links */
}

a {
    text-decoration: none;
    color:black;
    &:hover{
        color:  #5A5A5A;
    }
}

.navlink_icon{
    color: #5A5A5A;
    width:1.8vw;
    height:auto;
    &:hover{
        transform: scale(1.2);
        fill: #50B1C9;
    }
}
.navlink_icon {
    fill: #5A5A5A;
    stroke: #5A5A5A; /* Añadido para manejar el color del contorno */
    stroke-width: 2; /* Controla el grosor del contorno */
    width: 1.8vw;
    height: auto;
    transition: fill 0.3s ease, stroke 0.3s ease;
}

.navlink_icon:hover {
    fill: #50B1C9;
    stroke: #50B1C9; /* Asegúrate de cambiar también el color del contorno */
}

.profile_icon{
    width: 1.8vw;
    fill:#5a5a5a;
    &:hover{
        transform: scale(1.2);
        fill: #50B1C9 !important; 
    }
}

.profile_icon.hovered{
    fill: #50B1C9;
}


.activo{
    fill: #5A5A5A !important;
    color: #5A5A5A !important
}

/* App.css */

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .modal-inner-content {
    margin: 20px 0;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-buttons button.cancel {
    background-color: #ccc;
    color: #333;
  }
  
  .modal-buttons button.confirm {
    background-color: #4caf50;
    color: #fff;
  }

  @media only screen and (max-width: 767px){
    .logo {
        font-size: 0.5em;
        color: #50B1C9;
        float:left;
        margin-left: 2em;
        margin-top: 1.5em;
    }
    
    .header_navigation {
        margin-left: 45vw;
        align-items: flex-start;
        display:flex;
        margin-top: -1em;
        
    }
    
    .navlink {
        font-size: 1.7em;
        margin-right: 2vw;
    }
  }
  