.main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.left-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    width: 50vw;
}

.right-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
    height: 100vh;
}

.logo-login {
    width: 20%; 
    max-width: 30vw; 
    height: auto; 
    margin-bottom: 20px;
}

.image-login {
    width: 70%; 
    max-width: 70vw;
    height: auto;
    max-height: 60vh;
    border: 0.5% solid black;
}

.message-login {
    font-size: 2em; /* Tamaño de fuente relativo al tamaño del contenedor */
    font-weight: 800;
    line-height: 1.47em; /* Línea de altura relativa */
    letter-spacing: 0; /* Sin espaciado entre letras */
    text-align: center;
    color: #5A5A5A;
}

.box-user-login{
    margin: 20px;
    font-family: 'Montserrat';
    font-weight: 800;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textfield-user-login{
    border-radius: 7px;
    background-color: #F5F5F5;
}

.button-forgot-password{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #50B1C9;
    text-decoration: none;
    align-self: flex-end;
}

.help{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #50B1C9;
    text-decoration: none;
    align-self: flex-end;
}

.message-forgot-password{
    font-family: 'Montserrat';
    font-size: 30px;
    font-weight: 800;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
    color: #5A5A5A;
    margin-bottom: 0px;
}

.instructions-forgot-password{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #5A5A5A;
    margin-bottom: 30px;
    max-width: 100%;
    width: auto;
    height:auto
}