.desktop_perfil {
  background-color: #F5F5F5;
  width: 100%;
  overflow: hidden;
}

.desktop_perfil .div {
background-color: #F5F5F5;
height: 90vh;
position: relative;
width: 100vw;
}

.desktop_perfil .overlap-group { /* Fondo Gris */
background-color: #F5F5F5;
background-size: 100% 100%;
height: 100%;
left: 0;
position: absolute;
width: 100%;
overflow-x: hidden;
}

.desktop_perfil .rectangulo_principal {
background: linear-gradient(to right, #F5F5F5 7.31%, white 5%);
height: auto;
left: 0.4vw;
position: absolute;
top: 3vh;
width: 59.2vw;
align-content: left;
border-bottom: 15vh solid #F5F5F5;
border-radius: 0.5vw;
}

.desktop_perfil .rectangulo-arriba {
background-color: white;
background-size: 100% 100%;
height: auto;
left: 64vw;
position: absolute;
top: 3vh;
width: 30vw;
text-align: center;
border-radius: 0.5vw;
display: flex;
flex-direction: column;
width: 100%;
box-sizing: border-box;
overflow: hidden;
}

.desktop_perfil .rectangulo-desbloquear {
background-color: white;
background-size: 100% 100%;
height: auto;
left: 64vw;
position: absolute;
top: 35vw;
width: 30vw;
text-align: center;
border-radius: 0.5vw;
padding-bottom: 1vw;
}


#desbloquear-busqueda{
background-color: #50B1C9;
color: white;
border-radius: 47vw;
width: 20vw;
height: 2.5vw;
font-family: 'Montserrat';
font-size: 1vw;
margin-top: 1vw;
margin-left: 5vw;
display: block;
text-align: center;
border-radius: 8vw;
border: 0;
padding-top: 1vw;
background-color: #50B1C9;
color: white;
text-decoration: none;
text-align: center;
}
#desbloquear-busqueda:hover {
background: #50B1C9;
transition: 0.5s;
color:white;
}
#desbloquear-busqueda.disabled {
background-color: grey;
color: white;
pointer-events: none;
}
.desktop_perfil .segundo-rectangulo {
background-color: white;
background-size: 100% 100%;
height: auto;
margin-top: 3vh;
width: 29.5vw;
left: 64vw;
position: absolute;
top: 60vh;
}

.desktop_perfil .flex-container{
display: flex; 
flex-direction: row;
}

.desktop_perfil .group {
height: 18.5vh;
position: relative;
width: 19vw;
margin-left: 1vw;
margin-right: 1vw;
border: 70px solid #EE42961A;
border-bottom: transparent;
background-color:  white;
border-radius: 220px 220px 0 0;
}

.desktop_perfil .rectangle-fucsia {
height: 27.5vh;
width: 28vw;
background-color: #EE42961A;
margin-left: 2vw;
}

@font-face {
font-family: 'Montserrat';
src: url('/public/fonts/Montserrat-SemiBold.ttf') format('ttf');
}
.desktop_perfil .rectangulo_seccion {
width: 57.3vw;
text-align: left;
height: auto;
margin-top: -2.5vw;

}
.message-home {
  font-family: 'Montserrat';
  font-size: 1.1vw; 
  font-weight: 700;
  letter-spacing: 0; 
  text-align: left;
  margin-left: 6vw;
  margin-top: 6.5vw;
  color: #5A5A5A;
}
.message-home2 {
font-family: 'Montserrat';
font-size: 1.1vw; 
font-weight: 700;
letter-spacing: 0; 
text-align: left;
margin-left: 6vw;
color: #5A5A5A;
}
.message-home3 {
font-family: 'Montserrat';
font-size: 1.1vw; 
font-weight: 700;
letter-spacing: 0; 
text-align: center;
color: #5A5A5A;
}
.texto-rectangulo-desbloquear {
font-family: 'Montserrat';
font-size: 1.1vw;
font-weight: 700;
letter-spacing: 0;
color: #5A5A5A;
text-align: center;
}

.busqueda-image {
display: block;
margin: -2.5vw 0 0 28vw; 
max-width: 6vw;
height: auto; 
}

#busqueda{
margin-left: 21.5vw;
background-color: #50B1C9;
color: white;
border-radius: 47vw;
width: 17vw;
height: 3.2vh;
font-size: 0.8vw;
text-align: center;
padding-top: 0.5vw;
padding-bottom: 0.1vw;
margin-top: 0vh;
margin-left: 23vw;
}
#busqueda:hover {
background: #50B1C9;
transition: 0.5s;
color:white;
}
#busqueda.disabled {
background-color: grey;
color: white;
pointer-events: none;
}
.circle {
line-height: 4vh; 
width: 3vw;
height: 3vw;
border-radius: 50%; 
background-color: #F5F5F5;
margin-left: 6vw;
}
.rectangle {
line-height: 4vh; 
margin-left: 1vw;
width: 60vw; 
height: 0.5vw;
background-color: #F5F5F5; 
}
.rectangle2 {
line-height: 4vh; 
margin-left: 1vw;
width: 60vw; 
height: 0.3vw;
background-color: #F5F5F5; 
}
.rectangle3 {
line-height: 4vh; 
margin-left: 0vw;
width: 60vw; 
height: 0.2vw;
background-color: #F5F5F5; 
}

.subcontainerMiPerfil {
  width: 49vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 0.5px solid #D9D9D9;
  background-color: #fff;
  margin: 0 5vw;
  text-align: left;
  flex-wrap: wrap; /* Permitir que los elementos se envuelvan en pantallas pequeñas */
}

.top-matches-list {
  list-style: none;
  display: flex;
  padding: 0;
  margin-left: 1.5vw;
  flex-direction: column;
  align-items: left;
  width: 100%;
}
.match-item {
display: flex;
align-items: center;
margin-left: 1vw;
margin-bottom: 1vw; /* Espacio entre ítems */
width: 100%;
}

.match-circle {
font-family: 'Montserrat';
font-size: 1.4vw;
width: 4vw;
height: 4vw;
border-radius: 50%;
border: 0.15vw solid #50B1C9;
color: #50B1C9;
display: flex;
align-items: center;
justify-content: center;
margin-right: 2vw; /* Espacio entre el círculo y el texto */
}

.match-details {
display: flex;
flex-direction: column;
}

.match-name {
font-family: 'Montserrat';
font-size: 1.1vw; 
font-weight: 700;
text-align: left;
color: #5A5A5A;
}

.match-cargo {
font-size: 0.8vw; 
text-align: left;
color: #5A5A5A;
font-family: 'Montserrat';
}


#atras-detalles{
width: 20vw;
height: 2.5vw;
font-size: 0.8vw;
font-family: 'Montserrat';
border-radius: 8vw;
border: 0;
padding-top: 0.5vw;
padding-bottom: 0.4vw;
background-color: #50B1C9;
color: white;
text-decoration: none;
margin-top: 2vw;
position: absolute;
margin-left: -10vw;
text-align: center;
}
#atras{
width: 25vw;
height: 2vw;
font-size: 0.8vw;
border-radius: 30px;
border: 0;
padding-left: 2vw; 
padding-right: 2vw; 
padding-top: 0.4vw;
padding-bottom: 0.8vw;
background-color: #50B1C9;
color: white;
text-decoration: none;
margin-top: 1.5vw;
position: absolute;
margin-left: -12vw;
text-align: center;
}
.num-match {
font-family: 'Montserrat';
font-size: 2vw; 
line-height: 4vh; 
letter-spacing: 0; 
text-align: center;
color: #50B1C9;
position: absolute;
margin-top: -0.6vw;
margin-left: 1vw;
}
.text-match {
font-family: 'Montserrat';
font-size: 1vw; 
letter-spacing: 0; 
text-align: left;
color: #50B1C9;
position: absolute;
margin-top: 1.4vw;
margin-left: 1vw;
font-weight: 700;
line-height: 4vh; 
letter-spacing: 0; 
text-align: left;
}

.busqueda-title {
font-family: 'Montserrat';
font-size: 1vw; 
font-weight: 400;
letter-spacing: 0; 
text-align: left;
margin-left: 1.4vw;
color: #5A5A5A;
padding: -0.5vw;
}
.busqueda-resp {
padding: -0.5vw;
font-family: 'Montserrat';
font-size: 1vw; 
font-weight: 400;
letter-spacing: 0; 
text-align: left;
margin-top: -0.6vw;
margin-left: 1.4vw;
color: #9E9D9D;
margin-bottom: 0.2vw;
}
.fecha2 {
font-family: 'Montserrat';
font-size: 0.8vw; 
font-weight: 400;
letter-spacing: 0; 
text-align: left;
margin-top: -2.3vw;
margin-left: 20vw;
color: #9E9D9D;
}

.num-por {
font-family: 'Montserrat';
font-size: 1.4vw; 
line-height: 4vh; 
letter-spacing: 0; 
text-align: center;
color: #50B1C9;
position: absolute;
margin-top: 0vw;
margin-left: 0.1vw;
}

.box-por {
width: 2.5vw; 
height: 2.5vw;
padding: 1vw;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
margin-top: 0.7vw;
margin-bottom: 0.7vw;
margin-left: 1vw;
border-radius: 50%;
border: 0.15vw solid #50B1C9;
}

/* Estilo personalizado para el checkbox */
.input-checkbox {
-webkit-appearance: none;
appearance: none;
background-color: #f0f0f0;
margin-left: 1.4vw;
font: inherit;
color: currentColor;
width: 1.5vw; /* Ajustado a vw */
height: 1.5vw; /* Ajustado a vw */
border: 0.1vw solid #cacece; /* Ajustado a vw */
border-radius: 0.5vw; /* Bordes redondeados, ajustado a vw */
transform: translateY(-0.075em);
display: grid;
place-content: center;
}

/* Estilo para cuando el checkbox está marcado */
.input-checkbox:checked {
background-color: #50B1C9;
border-color: #50B1C9;
}

/* Estilo para el ícono de verificación cuando el checkbox está marcado */
.input-checkbox:checked::before {
content: '✓';
font-size: 1vw; /* Ajustado a vw */
color: white;
}

/* Añadiendo un efecto de hover */
.input-checkbox:hover {
filter: brightness(90%);
}

/* Añadiendo transiciones para suavizar el cambio de estados */
.input-checkbox {
transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.subcontainerDetalle {
  display: flex;
  flex-direction: column;
  flex: 3; /* Permitir que el contenedor central ocupe más espacio */
 /* Espaciado entre los contenedores */
}

.nombre-detalle {
font-family: 'Montserrat';
font-size: 1.1vw; 
font-weight: 700;
letter-spacing: 0; 
text-align: left;
padding: 0; 
margin-left: 2vw;
color: #5A5A5A;
}
.otro-detalle {
font-family: 'Montserrat';
font-size: 0.9vw; 
font-weight: 400;
letter-spacing: 0; 
text-align: left;
margin-top: -0.5vw;
margin-left: 2vw;
color: #9E9D9D;
}
.otro-detalle2 {
font-family: 'Montserrat';
font-size: 0.9vw; 
font-weight: 400;
letter-spacing: 0; 
text-align: left;
margin-top: -0.5vw;
margin-left: 2vw;
color: #9E9D9D;
}

.match-tot {
font-family: 'Montserrat';
font-size: 1vw; 
font-weight: 400;
letter-spacing: 0; 
text-align: center;
color: #5A5A5A;
}

.message-home4 {
font-family: 'Montserrat';
font-size: 2vw; 
font-weight: 500;
letter-spacing: 0; 
text-align: center;
color: #5A5A5A;
}

.loading-message {
font-family: 'Montserrat';
font-size: 1vw; 
font-weight: 400;
letter-spacing: 0; 
text-align: center;
color: #5A5A5A;
}
.search-input {
padding: 0.7vw 0.5vw;
border: 0.1vw solid #F5F5F5;
border-radius: 0.5vw;
margin-left: 4vw;
margin-top: -2.7vw;
height: 2.5vw;
width: 43vw; /* Ajusta el ancho considerando el padding */
box-sizing: border-box;
transition: border-color 0.3s;
background-color: #F5F5F5;
color: #9E9D9D;

box-shadow: none;
font-size: 0.9vw; /* Ajusta el tamaño de la fuente según el ancho de la ventana */
}

.search-input:focus {
outline: none;
border-color: #9E9D9D; /* Cambia el color al enfocar */
box-shadow: none;
}

.search-container {
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 0vw;
}

.box-match {
  width: 4vw; /* ajusta el tamaño de acuerdo a tus necesidades */
  height: 4vw;
  padding: 10px;
  text-align: center;
  display: flex;
  margin-top: 1.4vw;
  margin-left: -1vw;
  }

  .subcontainerCentral {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 2; /* Permitir que el contenedor central ocupe más espacio */
    margin: 0 1rem; /* Espaciado entre los contenedores */
}

.nombre-busqueda {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1vw;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0;
    text-align: left;
    margin-left: 2.5vw;
    color: #5A5A5A;
}

.cargo {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8vw;
    line-height: 1.5;
    letter-spacing: 0;
    text-align: left;
    margin-left: 2.5vw;
    color: #5A5A5A;
}

.subcontainerDerecho {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 8vw;
    position: relative;
    flex: 1; /* Permitir que el contenedor derecho ocupe el espacio disponible */
    max-width: 50%; /* Limitar el ancho máximo para pantallas grandes */
}

.fecha-y-basurero {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.fecha {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8vw;
    color: #5A5A5A;
    margin-right: 0.5rem;
}

.delete-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 1.1rem;
    height: 1.1rem;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.delete-button img {
    width: 100%;
    height: 100%;
}

.delete-button:hover {
    opacity: 1;
}

#detalle {
    width: fit-content;
    padding: 0.5rem 1rem;
    font-size: 0.8vw;
    border-radius: 6vw;
    background-color: #5A5A5A;
    color: white;
    text-decoration: none;
    text-align: center;
    margin-top: 10px;
}

#detalle:hover {
    background: #50B1C9;
    transition: 0.5s;
    color: white;
}